//Fix for not logged in header (blog, but could be on other pages)
body.not-logged-in h1.b-section-heading {
  @media screen and (min-width: 1024px) and (max-width: 1200px) {
    padding-top: 30px;
  }
}

.view-blogposts {
  & .view-content {
    max-width: 1200px;
    padding: 0 15px;
    margin: 0 auto;

    & ul {
      display: flex;
      flex-wrap: wrap;

      & li {
        /*width: calc(100% / 3 - 30px);*/
        box-sizing: border-box;
        margin: 0 15px 30px;
        padding: 0;
        flex: 1 0 calc(100% / 3 - 30px);
        max-width: calc(100% / 3 - 30px);

        @media screen and (max-width: 1024px) {
          flex: 1 0 calc(100% / 2 - 30px);
          max-width: calc(100% / 2 - 30px);
        }

        @media screen and (max-width: 680px) {
          flex: 1 0 calc(100% - 30px);
          max-width: calc(100% - 30px);
        }
      }
    }

  }
}

.blog-list {
  &__card {
    border-top: none;
    padding: 15px 15px 55px;
    position: relative;
  }

  &__single {
    height: 100%;
    position: relative;
    box-shadow: 0 4px 8px 0 rgba(29, 36, 35, 0.1);

    & a {
      position: absolute;
      bottom: 15px;
      left: 50%;
      transform: translateX(-50%);
      background-color: rgb(29, 36, 35);
      color: #dacea1;
      display: inline-block;
      margin-bottom: 0;
      font-weight: normal;
      text-align: center;
      vertical-align: middle;
      cursor: pointer;
      background-image: none;
      border: 1px solid transparent;
      white-space: nowrap;
      text-transform: uppercase;
      padding: 0 30px 0 20px;
      font-size: 16px;
      line-height: 40px;
      height: 40px;
      border-radius: 0px;
      user-select: none;

      &:hover {
        background-color: rgb(28, 127, 77);
        color: #fff;
        text-decoration: none;
        transition: .2s;
      }

      &::after {
        content: '';
        background: url('../../mgtheme/images/btn-arr.png') center center no-repeat;
        width: 9px;
        height: 36px;
        display: inline-block;
        padding-left: 2em;
        margin: 2px -1.5em 0 0;
        position: absolute;
      }
    }
  }

  &__image-container img {
    max-width: 100%;
    height: auto;
  }

  &__summary {
    /*max-height: 140px;
    min-height: 140px;*/
    /*max-height: 80px;
    min-height: 80px;
    overflow: hidden;
    line-height: 1.5em;

    &::after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background: linear-gradient(transparent 4.5em, white);
    }*/

    position: relative;
    line-height: 1.5em;
    height: 3em; /* exactly two lines */
    overflow: hidden;

    &:after {
      content: "";
      text-align: right;
      position: absolute;
      bottom: 0;
      right: 0;
      width: 90%;
      height: 1.5em;
      background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 100%);
    }
  }
}

.page-blog{
  /*.pager > .pager-item a{
    height: 40px;
    width: 40px;
    background: rgba(55, 52, 49, 0.8);
    color: #fff;;
    display: block;
  }*/
  .pager .pager-current{
    font-size: 26px;
    /*background: rgb(115, 169, 193);
    color: #fff;
    height: 40px;
    width: 40px;*/
  }
}

section.blog-section {
  max-width: 1140px;
  padding: 0 15px 0;

  & .field-label {
    display: none;
  }
}

.blogpost {
  &-back-bottom-link {
    margin: 0 auto;
    display: flex;
    width: max-content;
    margin-bottom: 15px;
  }
}

article.blogpost {
  margin-bottom: 32px;
  padding: 0 15px;

  //h1 {
  //  place-self: center;
  //  font-size: 40px;
  //}

  & header.row {
    margin-top: 5px;

    @media screen and (max-width: 480px) {
      padding: 0 15px;
    }
  }

  .field-items {
    width: 928px;
    margin: 0 auto;
  }

  header.row {
    padding: 8px 0;
    margin-bottom: 48px;
  }

  .blogpost-date {
    opacity: 0.7;
  }

  & .field .field-items .field-item p > img {
    max-width: 100%;
    width: 840px;
    //width: unset !important;
    height: auto !important;
    margin: 32px auto;
    display: flex;
  }

  @media screen and (max-width: 480px) {
    padding: 0;
  }

  table {
    width: 840px !important;
    margin: 0 auto;
  }

  td {
    border: 1px solid !important;
    padding: 8px !important;
    width: 210px !important;
  }
}