/*@import '__components/corona';*/
@import url("https://fonts.googleapis.com/css?family=Roboto");
@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed");
.jackpot {
  background-image: url("../images/bg-jackpot.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-color: #200A1E;
  height: 100%;
  position: relative;
  overflow: hidden;
}
.jackpot__coins {
  background-image: url("../images/coins.png");
  background-size: 100% auto;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.jackpot__container {
  max-width: 1280px;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.jackpot__container > * {
  width: 100%;
}
.jackpot__current, .jackpot__live {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.jackpot__live {
  margin-bottom: 20px;
}
.jackpot__header {
  text-align: center;
  color: white;
  font-family: "Roboto", sans-serif;
  margin-top: 5%;
  margin-bottom: 4%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  overflow: hidden;
}
.jackpot__header {
  font-size: 15px;
}
@media screen and (min-width: 320px) {
  .jackpot__header {
    font-size: calc(15px + 21 * (100vw - 320px) / 960);
  }
}
@media screen and (min-width: 1280px) {
  .jackpot__header {
    font-size: 36px;
  }
}
.jackpot__header:before, .jackpot__header:after {
  background-color: #DBC881;
  content: "";
  display: inline-block;
  height: 2px;
  position: relative;
  vertical-align: middle;
  width: 50%;
}
.jackpot__header:before {
  right: 0.5em;
  margin-left: -50%;
}
.jackpot__header:after {
  left: 0.5em;
  margin-right: -50%;
}
.jackpot__block {
  display: flex;
  width: 90%;
  justify-content: space-between;
  padding-left: 150px;
  padding-right: 150px;
  position: relative;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: auto;
  align-items: center;
  margin-bottom: 20px;
}
.jackpot__block:last-child {
  margin-bottom: 0;
}
.jackpot__block--dk {
  background-image: url("../images/bg-dk.svg");
  height: 19vw;
  max-height: 249px;
  width: 100%;
}
.jackpot__block--king-kong {
  background-image: url("../images/bg-king-kong.svg");
  height: 14vw;
  max-height: 181px;
}
.jackpot__block--fairy-ring {
  background-image: url("../images/bg-fairy-ring.svg");
  height: 14vw;
  max-height: 181px;
}
.jackpot__block--blazing {
  background-image: url("../images/bg-blazing.svg");
  height: 14vw;
  max-height: 181px;
}
.jackpot__block--mystery {
  background-image: url("../images/bg-mystery-jackpot.svg");
  height: 30vw;
  max-height: 419px;
}
.jackpot__block--poker {
  background-image: url("../images/bg-poker.svg");
  height: 30vw;
  max-height: 419px;
}
.jackpot__content {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 5vw;
  padding-right: 5vw;
}
@media screen and (min-width: 1280px) {
  .jackpot__content {
    padding-left: 50px;
    padding-right: 50px;
  }
}
.jackpot__content--big {
  padding-left: 10vw;
  padding-right: 10vw;
}
@media screen and (min-width: 1280px) {
  .jackpot__content--big {
    padding-left: 100px;
    padding-right: 100px;
  }
}
.jackpot__content--grid {
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}
.jackpot__title {
  font-family: "Roboto Condensed", Sans-Serif, serif;
  font-weight: bold;
  color: white;
  margin: 0;
  text-shadow: 0 3px 1px rgba(0, 0, 0, 0.65);
  -webkit-font-smoothing: antialiased;
}
.jackpot__title {
  font-size: 12px;
}
@media screen and (min-width: 320px) {
  .jackpot__title {
    font-size: calc(12px + 16 * (100vw - 320px) / 960);
  }
}
@media screen and (min-width: 1280px) {
  .jackpot__title {
    font-size: 28px;
  }
}
.jackpot__title--big {
  text-transform: uppercase;
}
.jackpot__title--big {
  font-size: 14px;
}
@media screen and (min-width: 320px) {
  .jackpot__title--big {
    font-size: calc(14px + 36 * (100vw - 320px) / 960);
  }
}
@media screen and (min-width: 1280px) {
  .jackpot__title--big {
    font-size: 50px;
  }
}
.jackpot__prize {
  margin: 0;
  font-family: "Roboto Condensed", Sans-Serif, serif;
  font-weight: bold;
  text-transform: uppercase;
  display: block;
  position: relative;
  background-image: linear-gradient(to bottom, #b18e51, #ffff97 47%, #bfad6f 49%, #e7d288);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-font-smoothing: antialiased;
  font-size: 70px;
  transform: translateZ(1px); /* fix Safari on Desktop */
}
.jackpot__prize {
  font-size: 15px;
}
@media screen and (min-width: 320px) {
  .jackpot__prize {
    font-size: calc(15px + 55 * (100vw - 320px) / 960);
  }
}
@media screen and (min-width: 1280px) {
  .jackpot__prize {
    font-size: 70px;
  }
}
.jackpot__prize--small {
  font-size: 45px;
  background-image: linear-gradient(to bottom, #B36F54, #FDD2BA 47%, #C5804F 49%, #F6B38C);
}
.jackpot__prize--small {
  font-size: 10px;
}
@media screen and (min-width: 320px) {
  .jackpot__prize--small {
    font-size: calc(10px + 35 * (100vw - 320px) / 960);
  }
}
@media screen and (min-width: 1280px) {
  .jackpot__prize--small {
    font-size: 45px;
  }
}
.jackpot__prize--big {
  font-size: 20px;
}
@media screen and (min-width: 320px) {
  .jackpot__prize--big {
    font-size: calc(20px + 60 * (100vw - 320px) / 960);
  }
}
@media screen and (min-width: 1280px) {
  .jackpot__prize--big {
    font-size: 80px;
  }
}
.jackpot__prize--blue {
  background-image: linear-gradient(to bottom, #7C8A8E, #E8F5F9 47%, #B1C4CB 49%, #FFFFFF);
}
@media all and (-ms-high-contrast: none) {
  .jackpot__prize {
    background: none;
    color: #DBC881;
  }
  .jackpot__prize--blue {
    color: #E2EDF5;
  }
  .jackpot__prize--small {
    color: #F3C39F;
  }
}
.jackpot__place {
  color: #CCC1C8;
  font-family: "Roboto", Sans-Serif, serif;
  margin: 0;
}
.jackpot__place {
  font-size: 10px;
}
@media screen and (min-width: 320px) {
  .jackpot__place {
    font-size: calc(10px + 8 * (100vw - 320px) / 960);
  }
}
@media screen and (min-width: 1280px) {
  .jackpot__place {
    font-size: 18px;
  }
}
.jackpot__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 2.5%;
}
@supports (-ms-ime-align: auto) {
  .jackpot__row {
    margin-top: 20px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1200px) {
  body.not-logged-in h1.b-section-heading {
    padding-top: 30px;
  }
}

.view-blogposts .view-content {
  max-width: 1200px;
  padding: 0 15px;
  margin: 0 auto;
}
.view-blogposts .view-content ul {
  display: flex;
  flex-wrap: wrap;
}
.view-blogposts .view-content ul li {
  /*width: calc(100% / 3 - 30px);*/
  box-sizing: border-box;
  margin: 0 15px 30px;
  padding: 0;
  flex: 1 0 calc(33.3333333333% - 30px);
  max-width: calc(33.3333333333% - 30px);
}
@media screen and (max-width: 1024px) {
  .view-blogposts .view-content ul li {
    flex: 1 0 calc(50% - 30px);
    max-width: calc(50% - 30px);
  }
}
@media screen and (max-width: 680px) {
  .view-blogposts .view-content ul li {
    flex: 1 0 calc(100% - 30px);
    max-width: calc(100% - 30px);
  }
}

.blog-list__card {
  border-top: none;
  padding: 15px 15px 55px;
  position: relative;
}
.blog-list__single {
  height: 100%;
  position: relative;
  box-shadow: 0 4px 8px 0 rgba(29, 36, 35, 0.1);
}
.blog-list__single a {
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgb(29, 36, 35);
  color: #dacea1;
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  text-transform: uppercase;
  padding: 0 30px 0 20px;
  font-size: 16px;
  line-height: 40px;
  height: 40px;
  border-radius: 0px;
  user-select: none;
}
.blog-list__single a:hover {
  background-color: rgb(28, 127, 77);
  color: #fff;
  text-decoration: none;
  transition: 0.2s;
}
.blog-list__single a::after {
  content: "";
  background: url("../../mgtheme/images/btn-arr.png") center center no-repeat;
  width: 9px;
  height: 36px;
  display: inline-block;
  padding-left: 2em;
  margin: 2px -1.5em 0 0;
  position: absolute;
}
.blog-list__image-container img {
  max-width: 100%;
  height: auto;
}
.blog-list__summary {
  /*max-height: 140px;
  min-height: 140px;*/
  /*max-height: 80px;
  min-height: 80px;
  overflow: hidden;
  line-height: 1.5em;

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(transparent 4.5em, white);
  }*/
  position: relative;
  line-height: 1.5em;
  height: 3em; /* exactly two lines */
  overflow: hidden;
}
.blog-list__summary:after {
  content: "";
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 90%;
  height: 1.5em;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgb(255, 255, 255) 100%);
}

.page-blog {
  /*.pager > .pager-item a{
    height: 40px;
    width: 40px;
    background: rgba(55, 52, 49, 0.8);
    color: #fff;;
    display: block;
  }*/
}
.page-blog .pager .pager-current {
  font-size: 26px;
  /*background: rgb(115, 169, 193);
  color: #fff;
  height: 40px;
  width: 40px;*/
}

section.blog-section {
  max-width: 1140px;
  padding: 0 15px 0;
}
section.blog-section .field-label {
  display: none;
}

.blogpost-back-bottom-link {
  margin: 0 auto;
  display: flex;
  width: max-content;
  margin-bottom: 15px;
}

article.blogpost {
  margin-bottom: 32px;
  padding: 0 15px;
}
article.blogpost header.row {
  margin-top: 5px;
}
@media screen and (max-width: 480px) {
  article.blogpost header.row {
    padding: 0 15px;
  }
}
article.blogpost .field-items {
  width: 928px;
  margin: 0 auto;
}
article.blogpost header.row {
  padding: 8px 0;
  margin-bottom: 48px;
}
article.blogpost .blogpost-date {
  opacity: 0.7;
}
article.blogpost .field .field-items .field-item p > img {
  max-width: 100%;
  width: 840px;
  height: auto !important;
  margin: 32px auto;
  display: flex;
}
@media screen and (max-width: 480px) {
  article.blogpost {
    padding: 0;
  }
}
article.blogpost table {
  width: 840px !important;
  margin: 0 auto;
}
article.blogpost td {
  border: 1px solid !important;
  padding: 8px !important;
  width: 210px !important;
}

.newsletter-block .content__gdpr,
.newsletter-block .content__gdprLegal {
  font-size: 12px;
}
.newsletter-block .newsletter-signup-text {
  text-transform: none;
  font-size: 14px;
}

#mobileMenu .select-language {
  display: flex;
}
#mobileMenu .select-language > li {
  flex: 1 1 100%;
}
#mobileMenu .select-language > li a {
  width: 100%;
  margin: 0;
  background-position: center center;
}
#mobileMenu .select-language > li a:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: -1px;
  background-color: white;
  width: 1px;
  z-index: 1;
}